import * as moment from 'moment-timezone';
import {
    DateRangeImpact,
    EventImpact,
    Region,
    SourceImpact,
} from '@cityair/modules/impact/service/api-model-impact';
import { CheckboxItem } from '@libs/common/types/checkbox-item';
import { ImpactMapObjectType } from '@cityair/modules/impact/models';
import { TEXTS } from '@libs/common/texts/texts';
import { AQI, HUM, PRES, TEMP } from '@libs/common/consts/substance.consts';
export const NEW_CONTROL_POINT_OBJ_TYPE = 'new_control_point';
export const NUMBER_ROUND_COORDINATES = 6;
export const INIT_IMPACT_DATE: DateRangeImpact = {
    start: moment().startOf('day').subtract(3, 'months').toISOString(),
    end: moment().add(1, 'day').endOf('day').toISOString(),
};
export const INIT_IMPACT_EVENT_DATE: DateRangeImpact = {
    start: moment().startOf('day').subtract(21, 'days').toISOString(),
    end: moment().add(1, 'day').endOf('day').toISOString(),
};
export const INIT_IMPACT_SOURCE_DATE: DateRangeImpact = {
    start: moment().startOf('day').subtract(8, 'days').toISOString(),
    end: moment().add(1, 'day').endOf('day').toISOString(),
};

export const INIT_IMPACT_REGION_DATE: DateRangeImpact = {
    start: moment().startOf('day').subtract(8, 'days').toISOString(),
    end: moment().add(1, 'day').endOf('day').toISOString(),
};
export const TEST_ANALYSIS_EVENT: EventImpact = {
    id: 'AKGQ-VEMO',
    start: '2024-10-02T20:20:00Z',
    end: '2024-10-03T02:00:00Z',
    forecast_end: null,
    param: 'PM25',
    pdk_value: 160,
    run_id: 40089,
    events: [
        {
            post_id: 'post_30',
            start: '2024-10-02T22:20:00Z',
            end: '2024-10-03T02:00:00Z',
            max_value: 194.0,
            data_source: [223, 224, 225, 221],
        },
        {
            post_id: 'post_5',
            start: '2024-10-02T20:20:00Z',
            end: '2024-10-02T23:40:00Z',
            max_value: 190.85,
            max_time: '2024-09-21T12:40:00Z',
            data_source: [222, 226, 219, 225],
        },
    ],
};
export const TEST_ANALYSIS_EVENTS_1: EventImpact = {
    id: 'AWZR-OZWA',
    start: '2024-09-18T08:20:00Z',
    end: '2024-09-18T14:00:00Z',
    forecast_end: null,
    param: 'PM25',
    pdk_value: 160,
    run_id: 39800,
    events: [
        {
            post_id: 'post_5',
            start: '2024-09-18T10:00:00Z',
            end: '2024-09-18T11:40:00Z',
            max_value: 163.525,
            data_source: [224, 221],
        },
        {
            post_id: 'post_1076',
            start: '2024-09-18T08:20:00Z',
            end: '2024-09-18T14:00:00Z',
            max_value: 238.4,
            data_source: [224, 221, 223],
        },
    ],
};
export const REGISTER_EVENTS = [TEST_ANALYSIS_EVENT, TEST_ANALYSIS_EVENTS_1];
export const INIT_OBJECT_ON_MAP: CheckboxItem[] = [
    {
        id: ImpactMapObjectType.posts,
        label: TEXTS.IMPACT.mapObjectTitle[ImpactMapObjectType.posts],
        selected: true,
    },
    {
        id: ImpactMapObjectType.points,
        label: TEXTS.IMPACT.mapObjectTitle[ImpactMapObjectType.points],
        selected: true,
    },
    {
        id: ImpactMapObjectType.sources,
        label: TEXTS.IMPACT.mapObjectTitle[ImpactMapObjectType.sources],
        selected: true,
    },
    {
        id: ImpactMapObjectType.regions,
        label: TEXTS.IMPACT.mapObjectTitle[ImpactMapObjectType.regions],
        selected: true,
    },
];
export const DATE_FOR_CITY = {
    date__gt: moment().subtract(20, 'minutes').toISOString(),
    date__lt: moment().toISOString(),
    mmtsList: [
        { id: TEMP},
        { id: PRES},
        { id: HUM},
        { id: AQI}
    ],

};

export const REGIONS: Region[] = [
    {
        id: 'region_2',
        group_id: '213',
        location_id: 'locality_2',
        name: 'Калининский',
        color: '#66B333',
        contribution: 4,
        posts: [],
        area: 24.5,
        density: 0,
    },
    {
        id: 'region_3',
        group_id: '213',
        location_id: 'locality_2',
        name: 'Заельцовский',
        color: '#66B333',
        contribution: 8,
        posts: ['post_475'],
        area: 71,
        density: 0,
    },
    {
        id: 'region_4',
        group_id: '213',
        location_id: 'locality_2',
        name: 'Железнодорожный',
        color: '#F1C21F',
        contribution: 18,
        posts: ['post_6', 'post_62'],
        area: 83,
        density: 0,
    },
    {
        id: 'region_5',
        group_id: '213',
        location_id: 'locality_2',
        name: 'Советский',
        color: '#FF7344',
        contribution: 32,
        posts: ['post_9', 'post_30', 'post_495', 'post_528'],
        area: 36.4,
        density: 2,
    },
    {
        id: 'region_6',
        group_id: '213',
        location_id: 'locality_2',
        name: 'Дзержинский',
        color: '#448EEC',
        contribution: 4,
        posts: [],
        area: 70.3,
        density: 0,
    },
    {
        id: 'region_7',
        group_id: '213',
        location_id: 'locality_2',
        name: 'Центральный',
        color: '#FDA7CE',
        contribution: 16,
        posts: ['post_2'],
        area: 6.2,
        density: 2,
    },
    {
        id: 'region_8',
        group_id: '213',
        location_id: 'locality_2',
        name: 'Барышевский',
        color: '#448EEC',
        contribution: 20,
        posts: ['post_3', 'post_3'],
        area: 128.6,
        density: 2,
    },
];

export const SOURCES: SourceImpact[] = [
    {
        id: 224,
        obj: 'source',
        bbox: [82.8749, 54.9994, 82.8749, 54.9994],
        name: 'ТЭЦ-2',
        params: '{}',
        centroid: {
            type: 'Point',
            coordinates: [82.8749, 54.9994],
        },
        group_id: 213,
        emissions: [
            {
                emissions_mass: 22,
                emissions_species_id: 9,
            },
            {
                emissions_mass: 2.4,
                emissions_species_id: 8,
            },
        ],
        species: '',
        sourceType: 'Точечный',
        emissionIntensity: 84.4,
        objectParams: {
            title: 'Дымовая труба цеха№2',
            type: 'Точечный',
            height: 110,
            square: 3,
        },
        pdvParams: {
            form: 'Круглая',
            pdv: '55',
            estimated_emissions: [
                '274 т/г, 8.7 г/сек',
                '2138 т/г, 67.8 г/сек',
                '249 т/г, 7.9 г/сек',
            ],
            simulation_parameters: ['30 г/сек', '300 г/сек', '500 г/сек'],
        },
    },
    {
        id: 225,
        obj: 'source',
        bbox: [82.6812, 55.0666, 82.6946, 55.0723],
        name: 'Агрокомплекс',
        params: '{}',
        centroid: {
            type: 'Point',
            coordinates: [82.6879, 55.06945],
        },
        group_id: 213,
        emissions: [
            {
                emissions_mass: 0.4,
                emissions_species_id: 8,
            },
            {
                emissions_mass: 50,
                emissions_species_id: 10,
            },
        ],
        species: '',
        sourceType: 'Площадной',
        emissionIntensity: 368.8,
        objectParams: {
            title: 'Дымовая труба цеха№2',
            type: 'Площадной',
            height: 11,
            square: 480000,
        },
        pdvParams: {
            form: 'Круглая',
            pdv: '55',
            estimated_emissions: ['56 т/г, 1.8 г/сек', '11037 т/г, 350 г/сек', '536 т/г, 17 г/сек'],
            simulation_parameters: ['30 г/сек', '300 г/сек', '500 г/сек'],
        },
    },
    {
        id: 226,
        obj: 'source',
        bbox: [83.00925, 55.0364, 83.0103, 55.0367],
        name: 'АГЗС',
        params: '{}',
        centroid: {
            type: 'Point',
            coordinates: [83.009775, 55.03655],
        },
        group_id: 213,
        emissions: [
            {
                emissions_mass: 0.5,
                emissions_species_id: 8,
            },
            {
                emissions_mass: 1,
                emissions_species_id: 9,
            },
        ],
        species: '',
        sourceType: 'Площадной',
        emissionIntensity: 231.1,
        objectParams: {
            title: 'Дымовая труба цеха№2',
            type: 'Площадной',
            height: 7,
            square: 250,
        },
        pdvParams: {
            form: 'Круглая',
            pdv: '55',
            estimated_emissions: ['66 т/г, 2.1 г/сек', '6307 т/г, 200 г/сек', '914 т/г, 29 г/сек'],
            simulation_parameters: ['30 г/сек', '300 г/сек', '500 г/сек'],
        },
    },
    {
        id: 228,
        obj: 'source',
        bbox: [83.1178, 54.9707, 83.1353, 54.9759],
        name: 'Первомайская',
        params: '{}',
        centroid: {
            type: 'Point',
            coordinates: [83.12655, 54.9733],
        },
        group_id: 213,
        emissions: [
            {
                emissions_mass: 3,
                emissions_species_id: 8,
            },
            {
                emissions_mass: 25,
                emissions_species_id: 9,
            },
        ],
        species: '',
        sourceType: 'Площадной',
        emissionIntensity: 168,
        objectParams: {
            title: 'Дымовая труба цеха№2',
            type: 'Площадной',
            height: 10,
            square: 1000000,
        },
        pdvParams: {
            form: 'Круглая',
            pdv: '55',
            estimated_emissions: [
                '299 т/г, 9.5 г/сек',
                '4730 т/г, 150 г/сек',
                '268 т/г, 8.5 г/сек',
            ],
            simulation_parameters: ['30 г/сек', '300 г/сек', '500 г/сек'],
        },
    },
    {
        id: 229,
        obj: 'source',
        bbox: [83.0572, 55.0096, 83.0572, 55.0096],
        name: 'ТЭЦ-5',
        params: '{}',
        centroid: {
            type: 'Point',
            coordinates: [83.0572, 55.0096],
        },
        group_id: 213,
        emissions: [
            {
                emissions_mass: 0.36,
                emissions_species_id: 8,
            },
            {
                emissions_mass: 18,
                emissions_species_id: 10,
            },
        ],
        species: '',
        sourceType: 'Точечный',
        emissionIntensity: 477.3,
        objectParams: {
            title: 'Дымовая труба цеха№2',
            type: 'Точечный',
            height: 260,
            square: 3,
        },
        pdvParams: {
            form: 'Круглая',
            pdv: '55',
            estimated_emissions: [
                '40 т/г, 1.3 г/сек',
                '12898 т/г, 409 г/сек',
                '2112 т/г, 67 г/сек',
            ],
            simulation_parameters: ['30 г/сек', '300 г/сек', '500 г/сек'],
        },
    },
    {
        id: 219,
        obj: 'source',
        bbox: [82.9161, 55.0642, 82.9161, 55.0642],
        name: 'Приборостроительный завод',
        params: '{}',
        centroid: {
            type: 'Point',
            coordinates: [82.9161, 55.0642],
        },
        group_id: 213,
        emissions: [
            {
                emissions_mass: 0.2,
                emissions_species_id: 8,
            },
            {
                emissions_mass: 1.4,
                emissions_species_id: 9,
            },
        ],
        species: '',
        sourceType: 'Точечный',
        emissionIntensity: 1.5005,
        objectParams: {
            title: 'Дымовая труба цеха№2',
            type: 'Точечный',
            height: 15,
            square: 3,
        },
        pdvParams: {
            form: 'Круглая',
            pdv: '55',
            estimated_emissions: [
                '0.015768 т/г, 0.0005 г/сек',
                '15,768 т/г, 0.5 г/сек',
                '31 т/г, 1 г/сек',
            ],
            simulation_parameters: ['30 г/сек', '300 г/сек', '500 г/сек'],
        },
    },
    {
        id: 220,
        obj: 'source',
        bbox: [83.309, 54.6561, 83.309, 54.6561],
        name: 'Цементный завод',
        params: '{}',
        centroid: {
            type: 'Point',
            coordinates: [83.309, 54.6561],
        },
        group_id: 213,
        emissions: [
            {
                emissions_mass: 1.2,
                emissions_species_id: 8,
            },
            {
                emissions_mass: 50.7,
                emissions_species_id: 10,
            },
        ],
        species: '',
        sourceType: 'Точечный',
        emissionIntensity: 276.34,
        objectParams: {
            title: 'Дымовая труба цеха№2',
            type: 'Точечный',
            height: 27,
            square: 3,
        },
        pdvParams: {
            form: 'Круглая',
            pdv: '55',
            estimated_emissions: [
                '1.26144 т/г, 0.04 г/сек',
                '5786 т/г, 183.5 г/сек',
                '2926 т/г, 92.8 г/сек',
            ],
            simulation_parameters: ['30 г/сек', '300 г/сек', '500 г/сек'],
        },
    },
    {
        id: 221,
        obj: 'source',
        bbox: [82.811, 54.941, 82.819, 54.944],
        name: 'Склад ТБО',
        params: '{}',
        centroid: {
            type: 'Point',
            coordinates: [82.815, 54.9425],
        },
        group_id: 213,
        emissions: [
            {
                emissions_mass: 0.2,
                emissions_species_id: 8,
            },
            {
                emissions_mass: 12,
                emissions_species_id: 10,
            },
        ],
        species: '',
        emissionIntensity: 327.9,
        sourceType: 'Площадной',
        objectParams: {
            title: 'Дымовая труба цеха№2',
            type: 'Площадной',
            height: 12,
            square: 281200,
        },
        pdvParams: {
            form: 'Круглая',
            pdv: '55',
            estimated_emissions: ['28 т/г, 0.9 г/сек', '9460 т/г, 300 г/сек', '851 т/г, 27 г/сек'],
            simulation_parameters: ['30 г/сек', '300 г/сек', '500 г/сек'],
        },
    },
    {
        id: 222,
        obj: 'source',
        bbox: [82.9929, 55.0976, 82.9929, 55.0976],
        name: 'ТЭЦ-4',
        params: '{}',
        centroid: {
            type: 'Point',
            coordinates: [82.9929, 55.0976],
        },
        group_id: 213,
        emissions: [
            {
                emissions_mass: 27,
                emissions_species_id: 9,
            },
            {
                emissions_mass: 3,
                emissions_species_id: 8,
            },
        ],
        species: '',
        sourceType: 'Точечный',
        emissionIntensity: 90.6,
        objectParams: {
            title: 'Дымовая труба цеха№2',
            type: 'Точечный',
            height: 98,
            square: 3,
        },
        pdvParams: {
            form: 'Круглая',
            pdv: '55',
            estimated_emissions: [
                '340 т/г, 10.8 г/сек',
                '2207 т/г, 70 г/сек',
                '309 т/г, 9.8 г/сек',
            ],
            simulation_parameters: ['30 г/сек', '300 г/сек', '500 г/сек'],
        },
    },
    {
        id: 223,
        obj: 'source',
        bbox: [82.8536, 55.0087, 82.8536, 55.0087],
        name: 'ТЭЦ-3',
        params: '{}',
        centroid: {
            type: 'Point',
            coordinates: [82.8536, 55.0087],
        },
        group_id: 213,
        emissions: [
            {
                emissions_mass: 0.5,
                emissions_species_id: 8,
            },
            {
                emissions_mass: 49,
                emissions_species_id: 9,
            },
        ],
        species: '',
        sourceType: 'Площадной',
        emissionIntensity: 194.2,
        objectParams: {
            title: 'Дымовая труба цеха№2',
            type: 'Площадной',
            height: 120,
            square: 3,
        },
        pdvParams: {
            form: 'Круглая',
            pdv: '55',
            estimated_emissions: [
                '15 т/г, 0.5 г/сек',
                '5550 т/г, 176 г/сек',
                '558 т/г, 17.7 г/сек',
            ],
            simulation_parameters: ['30 г/сек', '300 г/сек', '500 г/сек'],
        },
    },
];

export const SOURCES_MMT_LIST = [
    { name: 'PM2.5', value: 150 },
    { name: 'PM10', value: 150 },
    { name: 'SO<sub>2</sub>', value: 80 },
    { name: 'NO', value: 40 },
    { name: 'NO<sub>2</sub>', value: 20 },
    { name: 'H<sub>2</sub>S', value: 20 },
    { name: 'CO', value: 10 },
];

export const IMPACT_SOURCE_ON_REGION = [
    { id: 219, name: 'Приборостроительный завод', value: 30 },
    { id: 220, name: 'Цементный завод', value: 22 },
    { id: 221, name: 'Склад ТБО', value: 14 },
    { id: 222, name: 'ТЭЦ-4', value: 10 },
    { id: 223, name: 'ТЭЦ-3', value: 10 },
    { id: 224, name: 'ТЭЦ-2', value: 7 },
    { id: 229, name: 'ТЭЦ-5', value: 4 },
    { id: 228, name: 'Первомайская', value: 3 },
    { id: 226, name: 'АГЗС', value: 0 },
    { id: 225, name: 'Агрокомплекс', value: 0 },
];

export const NUMBER_EXCEEDANCES_FOR_REGION = [
    { name: 'PM2.5', value: 5, color: '#9966CB' },
    { name: 'PM10', value: 3, color: '#66B333' },
    { name: 'SO<sub>2</sub>', value: 1, color: '#F1C21F' },
    { name: 'NO', value: 1, color: '#FF7344' },
    { name: 'NO<sub>2</sub>', value: 1, color: '#448EEC' },
    { name: 'H<sub>2</sub>S', value: 1, color: '#FDA7CE' },
];

export const AVERAGE_CONCENTRATION_FOR_REGION = [
    { name: 'PM2.5', value: 300, color: '#9966CB' },
    { name: 'PM10', value: 200, color: '#66B333' },
    { name: 'SO<sub>2</sub>', value: 180, color: '#F1C21F' },
    { name: 'NO', value: 120, color: '#FF7344' },
    { name: 'NO<sub>2</sub>', value: 80, color: '#448EEC' },
    { name: 'H<sub>2</sub>S', value: 20, color: '#FDA7CE' },
];
