import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AnyLayer,
    EventData,
    LngLatLike,
    Map,
    MapboxEvent,
    RasterSource,
    ResourceType,
    Style,
} from 'mapbox-gl';
import {
    EMPTY,
    firstValueFrom,
    from,
    fromEvent,
    iif,
    lastValueFrom,
    Observable,
    of,
    Subject,
} from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    distinctUntilKeyChanged,
    filter,
    finalize,
    first,
    map,
    pluck,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { CITY_ZOOM_SHOW_HEXAGON } from '@cityair/config';
import {
    InfoPins,
    MapCenterAndZoom,
    MapControlPins,
    MapPins,
    MapPolygonInfo,
    RegionPins,
    SourceLine,
    SourcePins,
    WindowGlobalVars,
} from '@cityair/namespace';
import {
    createBoundaries,
    createTimeSequence,
    createTimeSequencePlumes,
    detectTouchDevice,
    getColorFromZone,
} from '@cityair/utils/utils';
import { TEXTS } from '@libs/common/texts/texts';

import { environment } from 'environments/environment';
import { RunPlume, windLayerParams } from '@cityair/modules/plumes/services/run/models';
import { GroupMapSettings } from '@libs/common/types/group-map-settings';
import { GroupTilePlayerSettings } from '@libs/common/models/feature-config';
import { GroupExtConfigName } from '@libs/common/enums/group-ext-config-name';
import {
    DEFAULT_MAP_STYLE,
    GroupFeaturesService,
} from '@cityair/modules/core/services/group-features/group-features.service';
import { VangaAuthService } from '@cityair/modules/core/services/vanga-auth/vanga-auth.service';
import {
    addAlert,
    mapLoaded,
    refreshVangaToken,
    setMapClickState,
} from '@cityair/modules/core/store/actions';
import {
    getMarkerState,
    selectCurrentMapStyleType,
    selectCurrentTime,
    selectCurrentTimeIndex,
    selectInitMap,
    selectIsCityMode,
    selectIsShowOnMapLensForecast,
    selectIsShowOnMapPublicForecast,
    selectLensForecastConfig,
    selectMapClickState,
    selectPublicForecastConfig,
    selectTimeRange,
    selectVangaTokenStatus,
} from '@cityair/modules/core/store/selectors';
import {
    getCurrentGroup,
    selectMapStyleTypes,
} from '@cityair/modules/core/store/group/group.feature';
import { TIMELINE_STEP } from '@cityair/libs/shared/utils/config';
import {
    currentForecastMmt,
    isValidToken,
    selectForecastCurrentTime,
    selectForecastDataForMap,
    selectForecasts,
    selectForecastTimeRange,
    selectIsShowForecastLayerOnMap,
} from '@cityair/modules/forecast/store/selectors';
import {
    isActivePlumes,
    isWindShowPlumesOnMap,
    selectActiveRunDates,
    selectPlumesTimeRange,
    selectPlumeTilesParams,
    selectPlumeWindParams,
    showLayerOnMap as showPlumesLayerOnMap,
} from '@cityair/modules/plumes/store/selectors';
import { MapStyleType } from '@libs/common/enums/map-style-type.type';
import { DomainTilesPlayer } from './domain-tiles-player/domain-tiles-player';
import { Substance } from './domain-tiles-player/substance.enum';
import { IAuthorizeHelper } from './domain-tiles-player/domain-config.type';
import { MapboxFacadeService } from './mapbox-facade.service';
import { TilePlayer } from './tile-player';
import { MAIN_PAGES } from '@libs/common/enums/main-pages';
import { markerState } from '@libs/common/enums/marker-state.enum';
import { selectPlayer } from '@libs/shared-ui/components/timeline-panel/store/selectors/core.selectors';
import {
    playerReady,
    playerSetManaged,
    playerSetProgress,
} from '@libs/shared-ui/components/timeline-panel/store/core.actions';
import { PlumesTilesPlayer } from '@cityair/modules/plumes/services/plumes-tiles-player/plumes-tiles-player';
import { loadWindData, WindVector } from './windVector';
import MapboxActions from './mapboxActions';
import { NameModules } from '@libs/common/enums/name-modules';
import { setControlPointLoading } from '@cityair/modules/plumes/store/actions';
import {
    selectActiveMmtPublicForecast,
    selectIsShowLensForecast,
    selectIsShowPublicForecast,
    setMmtsPublicForecast,
} from '@cityair/modules/core/store/public-forecast/public-forecast.feature';
import { OSM_STYLE, OUTDOOR_STYLE, SATELLITE_STYLE } from '@libs/common/consts/map.const';
import {
    selectMapStyleLoading,
    setLoadingStyle,
} from '@cityair/modules/core/store/map/map.feature';
import {
    selectImpactTilesParams,
    selectImpactTimeRange,
    selectIsActiveImpact,
    selectActiveStation,
    selectErrorContributionData,
    selectIsLoadingContributionData,
    selectImpactWindParams,
    selectShowWindOnMap,
    selectRunDates,
    selectImpactRegionsForMap,
    selectShowLayerImpactOnMap,
} from '@cityair/modules/impact/store/impact.feature';
import {
    selectDomain,
    selectIsActiveNetwork,
    selectPolygons,
    selectIsShowNetworkGridLayer,
} from '@cityair/modules/network/store/network.feature';
import { DEMO_IMPACT_POLYGON } from '@libs/common/consts/demo-impact-groups';
import { RunImpact } from '@cityair/modules/impact/service/api-model-impact';

declare let window: WindowGlobalVars;

const EMPTY_MAP_STYLE = {
    version: 8,
    name: 'Empty',
    center: [0, 0],
    zoom: 0,
    sources: {},
    layers: [],
};
const PUBLIC_FORECASTS_BUCKET_URL = `${environment.tile_server_url}/v1/public/forecast`;
const RESTRICTED_BUCKET_URL = `${environment.tile_server_url}/v1/r`;

export type ExtraLayer = {
    id: string;
    source: RasterSource;
    accessToken?: string;
};

@Component({
    selector: 'mapbox-map',
    templateUrl: 'mapbox.component.html',
    styleUrls: ['mapbox.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapboxMapComponent implements OnInit, OnDestroy {
    @Input() zoom?: number;
    @Input() center?: LngLatLike;

    @Input() postPins?: MapPins;
    @Input() cityPins?: MapPins;
    @Input() notificationSelectedPins?: MapPins;
    @Input() correlationPins?: MapPins;
    @Input() sourceLine?: SourceLine;
    @Input() polygon?: MapPolygonInfo;
    @Input() infoPins?: InfoPins;
    @Input() controlPointPins?: MapControlPins;
    @Input() sourcePins?: SourcePins;
    @Input() regionPins?: RegionPins;

    @Input() groupFeaturesLayer?: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
    @Input() pinsAreaData?: Observable<GeoJSON.FeatureCollection<GeoJSON.LineString>>;

    @Output() mapDragEnd = new EventEmitter<MapCenterAndZoom>();
    @Output() zoomChanged = new EventEmitter<number>();

    DEMO_IMPACT_POLYGON = DEMO_IMPACT_POLYGON;
    isCityMode$ = this.store.select(selectIsCityMode);
    selectActiveStation = selectActiveStation;
    selectIsLoadingContributionData = selectIsLoadingContributionData;
    selectErrorContributionData = selectErrorContributionData;
    selectIsShowOnMapPublicForecast = selectIsShowOnMapPublicForecast;
    selectIsShowOnMapLensForecast = selectIsShowOnMapLensForecast;
    forecastNameModule = NameModules.forecast;
    selectDomain = selectDomain;
    selectIsActiveNetwork = selectIsActiveNetwork;
    selectPolygons = selectPolygons;
    selectIsShowNetworkGridLayer = selectIsShowNetworkGridLayer;
    selectImpactRegionsForMap = selectImpactRegionsForMap;
    getMarkerState = (id: string) => this.store.select(getMarkerState(id));
    onDestroy$ = new Subject<void>();
    isTouchDevice: boolean;
    isStyleLoading = true;
    style: Style | string = EMPTY_MAP_STYLE;
    map: Map;
    mapSettings: GroupMapSettings = {};

    markerState = markerState;
    GroupExtConfigName = GroupExtConfigName;
    showMarkersArea = false;
    TEXTS = TEXTS;
    MAIN_PAGES = MAIN_PAGES;
    getColorFromZone = getColorFromZone;

    timeStep = TIMELINE_STEP;

    resizeFn: () => void;

    showMap = false;

    pinTooltipText = '';

    tilePlayers: {
        [layerId: string]: TilePlayer;
    } = {};

    plumesAvailable: Observable<boolean>;
    impactAvailable: Observable<boolean>;
    showForecastLayer$: Observable<boolean>;
    showPublicForecastLayer$: Observable<boolean>;
    showLensForecastLayer$: Observable<boolean>;
    showPlumesLayer$: Observable<boolean>;
    showImpactLayer$: Observable<boolean>;
    domainTilesPlayer: DomainTilesPlayer;
    lensTilesPlayer: DomainTilesPlayer;
    domainTilesPlumesPlayer: DomainTilesPlayer;
    impactTilesPlayer: PlumesTilesPlayer;
    plumesTilesPlayer: PlumesTilesPlayer;
    domainTilesForecastPlayer: DomainTilesPlayer;
    private isAllowClick = false;

    availableExtraLayers: ExtraLayer[] = [];
    enabledExtraLayers: ExtraLayer[] = [];
    private currentMapStyleType = MapStyleType.cityair;
    private useMapStyleSelector = false;
    private resizeMapTimeout: NodeJS.Timer;
    public windVector;
    public windLayer: AnyLayer;
    public isWindShowOnMap: boolean;

    constructor(
        private mapboxFacadeService: MapboxFacadeService,
        private groupFeaturesService: GroupFeaturesService,
        private vangaAuthService: VangaAuthService,
        public store: Store,
        private ngZone: NgZone,
        readonly mapboxActions: MapboxActions,
        private _cdr: ChangeDetectorRef
    ) {
        this.store.dispatch(setLoadingStyle({ payload: true }));
        this.plumesAvailable = this.store.select(isActivePlumes);
        this.impactAvailable = this.store.select(selectIsActiveImpact);
        this.showPublicForecastLayer$ = this.store.select(selectIsShowPublicForecast);
        this.showLensForecastLayer$ = this.store.select(selectIsShowLensForecast);
        // catch resize event -> stop wind animation and update wind layer
        fromEvent(window, 'resize')
            .pipe(
                takeUntil(this.onDestroy$),
                filter(() => this.map && this.isWindShowOnMap && this.windVector),
                tap(() => this.windVector?.stopAnimation())
            )
            .subscribe((data) => {
                clearTimeout(this.resizeMapTimeout);
                this.resizeMapTimeout = setTimeout(() => {
                    this.updateWindLayer();
                    this._cdr.markForCheck();
                }, 100);
            });
        this.store
            .select(selectInitMap)
            .pipe(
                takeUntil(this.onDestroy$),
                filter((v) => !!v)
            )
            .subscribe((groupInfo) => {
                this.enableMap(groupInfo);
            });
        this.store
            .select(selectCurrentMapStyleType)
            .pipe(
                takeUntil(this.onDestroy$),
                filter((v) => !!v)
            )
            .subscribe((type: MapStyleType) => {
                this.currentMapStyleType = type;
                if (this.map && this.useMapStyleSelector) {
                    if (this.isWindShowOnMap && this.windVector) {
                        const mapLayer = this.map?.getLayer('wind');
                        if (typeof mapLayer !== undefined) {
                            this.map?.removeLayer('wind');
                        }
                    }
                    this.store.dispatch(setLoadingStyle({ payload: true }));
                    this.setMapStyle();
                }
                this._cdr.markForCheck();
            });
        this.store
            .select(selectMapStyleTypes)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((types: MapStyleType[]) => {
                this.useMapStyleSelector = types?.length > 0;
                this._cdr.markForCheck();
                if (this.useMapStyleSelector) {
                    this.store.dispatch(setLoadingStyle({ payload: true }));
                }
            });

        this.mapboxFacadeService.stylesReady$
            .pipe(
                takeUntil(this.onDestroy$),
                filter((isReady) => isReady)
            )
            .subscribe(async () => {
                this.store.dispatch(setLoadingStyle({ payload: false }));
                if (!this.showMap) {
                    await this.createTilePlayers();
                    this.createPublicForecastImagePlayer();
                    this.createLensImagePlayer();
                    this.ngZone.run(() => {
                        this.showMap = true;
                        this._cdr.detectChanges();
                    });
                }
                if (this.isWindShowOnMap && this.windVector) {
                    if (this.currentMapStyleType === MapStyleType.cityair) {
                        this.map?.addLayer(this.windLayer, 'building');
                    } else {
                        this.map?.addLayer(this.windLayer);
                    }
                }
            });

        this.isTouchDevice = detectTouchDevice();

        store
            .select(selectMapClickState)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((state) => {
                this.isAllowClick = state?.isAllow;
                this._cdr.markForCheck();
            });
        store
            .select(isWindShowPlumesOnMap)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => {
                this.isWindShowOnMap = data;
                this.toggleWindLayer(data);
                this._cdr.markForCheck();
            });
        store
            .select(selectShowWindOnMap)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((data) => {
                this.isWindShowOnMap = data;
                this.toggleWindLayer(data);
            });
        store
            .select(getCurrentGroup)
            .pipe(
                takeUntil(this.onDestroy$),
                filter((g) => !!g && !!this.map)
            )
            .subscribe((group) => {
                if (group?.ext_config?.mapSettings?.bounds) {
                    this.mapSettings.bounds = group.ext_config.mapSettings.bounds;
                }
                this._cdr.markForCheck();
            });
        store
            .select(selectPlumeWindParams)
            .pipe(
                takeUntil(this.onDestroy$),
                filter((v) => !!v),
                distinctUntilKeyChanged('url')
            )
            .subscribe((data) => {
                this.getWindData(data);
            });
        store
            .select(selectImpactWindParams)
            .pipe(
                takeUntil(this.onDestroy$),
                filter((v) => !!v),
                distinctUntilKeyChanged('url')
            )
            .subscribe((data) => {
                this.getWindData(data);
            });
        this.setupForecastTilePlayer();

        this.setupPlumesTilePlayer();
        this.setupImpactTilePlayer();
    }

    ngOnInit() {
        this.store
            .select(selectMapStyleLoading)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((isStyleLoading) => {
                this.isStyleLoading = isStyleLoading;
                this._cdr.detectChanges();
            });
    }

    public mapDragEndHandler($event: MapboxEvent<MouseEvent | TouchEvent> & EventData) {
        this.mapDragEnd.emit({
            center: $event.target.getCenter(),
            zoom: $event.target.getZoom(),
        });
    }

    public moveStart() {
        this.windVector?.stopAnimation();
    }

    public moveEnd() {
        this.windVector?.startAnimation();
    }

    setupForecastTilePlayer() {
        this.store
            .select(selectPlayer)
            .pipe(
                takeUntil(this.onDestroy$),
                pluck('loading'),
                distinctUntilChanged(),
                switchMap((loading) => iif(() => loading, this.showForecastLayer$, EMPTY)),
                filter((showLayerOnMap) => showLayerOnMap),
                switchMap(() => this.store.pipe(selectForecastTimeRange, take(1)))
            )
            .subscribe(({ begin, end }) => {
                const timeSequence = createTimeSequence(begin, end);
                this.preloadLayerImagesNew(this.domainTilesForecastPlayer, timeSequence);
            });

        this.showForecastLayer$ = this.store.select(selectIsShowForecastLayerOnMap);

        this.showForecastLayer$
            .pipe(
                takeUntil(this.onDestroy$),
                filter((isEnabled) => isEnabled),
                switchMap(() => this.mapboxFacadeService.stylesReady$),
                filter((isReady) => isReady),
                switchMap(() => this.store.select(selectForecasts)),
                filter((forecast) => forecast.length !== 0),
                switchMap(() => this.store.select(isValidToken)),
                filter((isValid) => isValid)
            )
            .subscribe(() => {
                this.ngZone.run(() => {
                    this.createForecastImagePlayer();
                    this.store.dispatch(playerSetManaged({ payload: true }));
                });
            });

        this.showForecastLayer$
            .pipe(
                takeUntil(this.onDestroy$),
                distinctUntilChanged(),
                filter((isEnabled) => !isEnabled)
            )
            .subscribe(() => {
                this.store.dispatch(playerSetManaged({ payload: false }));
            });
    }

    setupPlumesTilePlayer() {
        this.showPlumesLayer$ = this.store.select(showPlumesLayerOnMap);

        this.store
            .select(selectPlayer)
            .pipe(
                takeUntil(this.onDestroy$),
                pluck('loading'),
                distinctUntilChanged(),
                switchMap((loading) => iif(() => loading, this.showPlumesLayer$, EMPTY)),
                filter((showLayerOnMap) => showLayerOnMap),
                switchMap(() => this.store.pipe(selectPlumesTimeRange, take(1)))
            )
            .subscribe(({ begin, end }) => {
                const timeSequence = createTimeSequencePlumes(begin, end);
                this.preloadLayerImagesNew(this.plumesTilesPlayer, timeSequence);
            });

        this.showPlumesLayer$
            .pipe(
                takeUntil(this.onDestroy$),
                filter((isEnabled) => isEnabled),
                switchMap(() => this.mapboxFacadeService.stylesReady$),
                filter((isReady) => isReady),
                switchMap(() => this.store.select(selectActiveRunDates)),
                filter((run) => !!run),
                distinctUntilKeyChanged('id')
            )
            .subscribe((run) => {
                this.createPlumesPlayer(run);
                this.store.dispatch(playerSetManaged({ payload: true }));
            });

        this.showPlumesLayer$
            .pipe(
                takeUntil(this.onDestroy$),
                distinctUntilChanged(),
                filter((isEnabled) => !isEnabled)
            )
            .subscribe(() => {
                this.store.dispatch(playerSetManaged({ payload: false }));
            });
    }

    setupImpactTilePlayer() {
        this.showImpactLayer$ = this.store.select(selectShowLayerImpactOnMap);

        this.store
            .select(selectPlayer)
            .pipe(
                takeUntil(this.onDestroy$),
                pluck('loading'),
                distinctUntilChanged(),
                switchMap((loading) => iif(() => loading, this.showImpactLayer$, EMPTY)),
                filter((showLayerOnMap) => showLayerOnMap),
                switchMap(() => this.store.pipe(selectImpactTimeRange, take(1)))
            )
            .subscribe(({ begin, end }) => {
                const timeSequence = createTimeSequencePlumes(begin, end);
                this.preloadLayerImagesNew(this.plumesTilesPlayer, timeSequence);
            });

        this.showImpactLayer$
            .pipe(
                takeUntil(this.onDestroy$),
                filter((isEnabled) => isEnabled),
                switchMap(() => this.mapboxFacadeService.stylesReady$),
                filter((isReady) => isReady),
                switchMap(() => this.store.select(selectRunDates)),
                filter((run) => !!run),
                distinctUntilKeyChanged('id')
            )
            .subscribe((run) => {
                this.createImpactPlayer(run);
                this.store.dispatch(playerSetManaged({ payload: true }));
            });

        this.showImpactLayer$
            .pipe(
                takeUntil(this.onDestroy$),
                distinctUntilChanged(),
                filter((isEnabled) => !isEnabled)
            )
            .subscribe(() => {
                this.store.dispatch(playerSetManaged({ payload: false }));
            });
    }

    async preloadLayerImagesNew(
        domainTilesPlayer: DomainTilesPlayer | PlumesTilesPlayer,
        timeSequence: Set<number>
    ) {
        await domainTilesPlayer?.preloadImages(timeSequence, (percent: number) =>
            this.store.dispatch(playerSetProgress({ progress: percent / 100 }))
        );

        this.store.dispatch(playerReady());
    }

    enableMap(groupInfo) {
        this.clearMapLayers();
        this.setMapConfig(groupInfo);
    }

    tilesAuthorizerHelper: IAuthorizeHelper = {
        getAuthHeader: () => ({
            Authorization: `Bearer ${this.vangaAuthService.getAccessToken()}`,
        }),
        refreshToken: async () => {
            this.store.dispatch(refreshVangaToken());

            const vangaTokenIsReady = this.store.select(selectVangaTokenStatus).pipe(
                filter((isLoading) => !isLoading),
                take(1)
            );

            await lastValueFrom(vangaTokenIsReady);
        },
    };

    private createPublicForecastImagePlayer() {
        this.store
            .select(selectPublicForecastConfig)
            .pipe(
                takeUntil(this.onDestroy$),
                finalize(() => this.domainTilesPlayer?.destroy()),
                filter((domain) => !!domain),
                tap((domain) => {
                    this.domainTilesPlayer?.destroy();

                    this.domainTilesPlayer = new DomainTilesPlayer(
                        'forecast',
                        {
                            url: PUBLIC_FORECASTS_BUCKET_URL,
                            domain,
                        },
                        this.store.pipe(selectCurrentTimeIndex),
                        this.store.pipe(selectTimeRange),
                        this.showPublicForecastLayer$
                    );
                    this.store.dispatch(setMmtsPublicForecast({ payload: domain.substances }));
                }),
                switchMap(() => this.store.select(selectActiveMmtPublicForecast)),
                filter((mmt) => !!mmt)
            )
            .subscribe((substance: Substance) => {
                this.domainTilesPlayer?.selectSubstance(substance);
            });
    }

    private createLensImagePlayer() {
        this.store
            .select(selectLensForecastConfig)
            .pipe(
                takeUntil(this.onDestroy$),
                finalize(() => this.lensTilesPlayer?.destroy()),
                filter((domain) => !!domain),
                tap((domain) => {
                    this.lensTilesPlayer?.destroy();

                    this.lensTilesPlayer = new DomainTilesPlayer(
                        'forecast',
                        {
                            url: PUBLIC_FORECASTS_BUCKET_URL,
                            domain,
                        },
                        this.store.pipe(selectCurrentTimeIndex),
                        this.store.pipe(selectTimeRange),
                        this.showLensForecastLayer$
                    );
                }),
                switchMap(() => of('PM10')),
                filter((mmt) => !!mmt)
            )
            .subscribe((substance: Substance) => {
                this.lensTilesPlayer?.selectSubstance(substance);
            });
    }

    private createForecastImagePlayer() {
        this.store
            .select(selectForecastDataForMap)
            .pipe(
                filter((v) => v !== null),
                map(({ groupId, domain }) => ({
                    groupId,
                    domain,
                })),
                tap((data) => {
                    this.domainTilesForecastPlayer?.destroy();
                    this.domainTilesForecastPlayer = new DomainTilesPlayer(
                        'forecast',
                        {
                            url: RESTRICTED_BUCKET_URL,
                            domain: {
                                ...data.domain,
                                slug: [data.groupId, 'forecast', data.domain.slug].join('/'),
                            },
                        },
                        this.store.pipe(
                            selectForecastCurrentTime,
                            filter((v) => !!v)
                        ),
                        this.store.pipe(selectForecastTimeRange),
                        this.showForecastLayer$,
                        this.tilesAuthorizerHelper
                    );
                }),
                switchMap(() => this.store.select(currentForecastMmt)),
                filter((mmt) => !!mmt)
            )
            .subscribe((substance: Substance) => {
                this.domainTilesForecastPlayer.selectSubstance(substance);
            });
    }

    private createPlumesPlayer(run: RunPlume) {
        this.store
            .select(getCurrentGroup)
            .pipe(first())
            .subscribe((currentGroup) => {
                this.plumesTilesPlayer?.destroy();
                this.plumesTilesPlayer = new PlumesTilesPlayer(
                    'plumes',
                    {
                        url: RESTRICTED_BUCKET_URL,
                        domain: {
                            slug: [currentGroup.id, 'plumes', run.id].join('/'),
                            substances: [],
                            coordinates: createBoundaries(run.domain.bbox),
                        },
                        timeStep: run?.step_minutes,
                    },
                    this.store.select(selectPlumeTilesParams).pipe(filter((params) => !!params)),
                    this.store.pipe(selectPlumesTimeRange),
                    this.showPlumesLayer$,
                    this.tilesAuthorizerHelper
                );
            });
    }

    private createImpactPlayer(run: RunImpact) {
        this.store
            .select(getCurrentGroup)
            .pipe(first())
            .subscribe((currentGroup) => {
                this.impactTilesPlayer?.destroy();
                this.impactTilesPlayer = new PlumesTilesPlayer(
                    'plumes',
                    {
                        url: RESTRICTED_BUCKET_URL,
                        domain: {
                            slug: [currentGroup.id, 'plumes', run.id].join('/'),
                            substances: [],
                            coordinates: createBoundaries(run.domain.bbox),
                        },
                        timeStep: run?.step_minutes,
                    },
                    this.store.select(selectImpactTilesParams).pipe(filter((params) => !!params)),
                    this.store.pipe(selectImpactTimeRange),
                    this.showImpactLayer$,
                    this.tilesAuthorizerHelper
                );
            });
    }
    private async _createTilePlayer(
        settings: GroupTilePlayerSettings,
        tzOverride?: number,
        timeDelayMs: number = 0
    ) {
        const regenerateTilePlayer = ({ begin, end }) => {
            const { layerId } = settings;

            this.tilePlayers[layerId]?.destroy();

            this.tilePlayers[layerId] = new TilePlayer(
                this.store.pipe(
                    selectCurrentTime,
                    pluck('current'),
                    map((ts) => ts - timeDelayMs)
                ),
                [begin, end].map((d) => new Date(d)),
                settings
            );
        };

        const getTimelineRange = async () => {
            const time = await firstValueFrom(this.store.pipe(selectTimeRange));

            return {
                begin: time.begin - timeDelayMs,
                end: time.end - timeDelayMs,
            };
        };

        let range = await getTimelineRange();

        regenerateTilePlayer(range);

        this.store.pipe(selectCurrentTime, takeUntil(this.onDestroy$)).subscribe(async () => {
            const timelineRange = await getTimelineRange();

            if (range.begin !== timelineRange.begin || range.end !== timelineRange.end) {
                range = timelineRange;
                regenerateTilePlayer(range);
            }
        });
    }

    private async createTilePlayers() {
        const tpSettings = this.groupFeaturesService.getConfig(
            GroupExtConfigName.tilePlayerSettings
        ) as GroupTilePlayerSettings;

        if (tpSettings) {
            const settings = { ...tpSettings, layerId: 'default' };
            await this._createTilePlayer(settings);
        }
    }

    setMapConfig(groupInfo) {
        this.mapSettings = this.groupFeaturesService.getConfig(
            GroupExtConfigName.mapSettings
        ) as GroupMapSettings;

        const { tiles, tileSize, minzoom, maxzoom, accessToken } = this.mapSettings;

        if (tiles?.length && tiles[0].split('/{z}')[0]) {
            this.style = EMPTY_MAP_STYLE;

            const groupLayerId = 'group';

            this.addRasterLayer(
                groupLayerId,
                {
                    tiles,
                    tileSize,
                    minzoom,
                    maxzoom,
                },
                accessToken
            );

            this.toggleMapLayer(groupLayerId, true);
            this.mapboxFacadeService.skipCustomStyles();
        } else if (this.useMapStyleSelector && this.currentMapStyleType !== MapStyleType.cityair) {
            if (this.currentMapStyleType === MapStyleType.osm) {
                this.style = OSM_STYLE;
            } else if (this.currentMapStyleType === MapStyleType.satellite) {
                this.style = SATELLITE_STYLE;
            } else if (this.currentMapStyleType === MapStyleType.outdoor) {
                this.style = OUTDOOR_STYLE;
            }
            this.mapboxFacadeService.skipCustomStyles();
        } else {
            this.style = this.mapSettings.style;

            if (this.mapSettings.style === DEFAULT_MAP_STYLE) {
                this.mapboxFacadeService.applyCustomStyles();
            } else {
                this.mapboxFacadeService.skipCustomStyles();
            }
        }
        this._cdr.markForCheck();
    }

    addRasterLayer(
        id: string,
        options?: {
            tiles?: string[];
            tileSize?: number;
            minzoom?: number;
            maxzoom?: number;
        },
        accessToken?: string
    ) {
        this.addMapLayer({
            id,
            accessToken,
            source: {
                ...(options || {}),
                type: 'raster',
                url: options.tiles?.[0].split('/').slice(0, 3).join('/') || '',
            },
        });
    }

    private tileNotFoundHandler = (e: ErrorEvent & EventData) => {
        if (
            e.source &&
            e.source.type === 'image' &&
            e.source.url.startsWith(RESTRICTED_BUCKET_URL) &&
            e.source.url.indexOf('/raster/') !== -1
        ) {
            const rasterPlayers = {
                forecast: this.domainTilesPlayer,
                plumes: this.plumesTilesPlayer,
                forecastNew: this.domainTilesForecastPlayer,
            };

            const [id] = Object.entries(rasterPlayers).find(
                ([_, p]) => e.source.url.indexOf(p?.config.domain.slug) !== -1
            );

            rasterPlayers[id]?.clear();
        }
    };

    mapCreate(map: Map) {
        this.map = map;
    }

    mapboxLoad($event) {
        this.mapboxFacadeService.setMap(this.map);
        this.mapboxActions.setMapObject(this.map);
        this.store.dispatch(mapLoaded());
        this.map.on('error', this.tileNotFoundHandler);
    }

    ngOnDestroy() {
        Object.values(this.tilePlayers).map((tp) => tp?.destroy());
        this.map?.off('error', this.tileNotFoundHandler);

        this.onDestroy$.complete();
    }

    authorizeTileRequest = (url: string, resourceType: ResourceType) => {
        const [layer] = this.enabledExtraLayers;

        if (resourceType === 'Image' && url.startsWith(RESTRICTED_BUCKET_URL)) {
            return {
                url,
                headers: {
                    Authorization: `Bearer ${this.vangaAuthService.getAccessToken()}`,
                },
            };
        } else if (
            layer?.accessToken &&
            resourceType === 'Tile' &&
            url.startsWith(layer.source.url)
        ) {
            return {
                url,
                headers: {
                    Authorization: `Bearer ${layer.accessToken}`,
                },
            };
        }
    };

    currentZoom: number;

    onZoom(zoom: number) {
        this.zoomChanged.emit(zoom);
        this.currentZoom = zoom;
        this.showMarkersArea = this.mapSettings.showMarkersArea && zoom > CITY_ZOOM_SHOW_HEXAGON;
    }

    isGreaterThanMinZoom(minzoom: number) {
        return !isNaN(minzoom) && this.currentZoom >= minzoom;
    }

    public clickedMap($event) {
        if (this.isAllowClick) {
            this.store.dispatch(
                setMapClickState({
                    isAllow: this.isAllowClick,
                    coordinates: {
                        lat: $event.lngLat.lat,
                        lon: $event.lngLat.lng,
                    },
                })
            );
        }
    }

    // map layers
    toggleMapLayer(layerId: string, isEnabled: boolean) {
        const layer = isEnabled
            ? this.availableExtraLayers.find((layer) => layer.id === layerId)
            : null;
        this.enabledExtraLayers = layer ? [layer] : [];
    }

    addMapLayer(layer: ExtraLayer) {
        if (!this.availableExtraLayers.find((l) => l.id === layer.id)) {
            this.availableExtraLayers.push(layer);
        }
    }

    clearMapLayers() {
        this.enabledExtraLayers = [];
        this.availableExtraLayers = [];
    }

    private getWindData(params: windLayerParams) {
        const url = `${environment.tile_server_url}/${params.url}.png`;
        const token =
            params.url.indexOf('public') >= 0 ? null : this.vangaAuthService.getAccessToken();
        const loadImage$ = from(loadWindData(url, params.bboxDomain, [-20.0, 20.0], token));
        this.store.dispatch(setControlPointLoading({ payload: true }));
        loadImage$.pipe(catchError((error) => of(null))).subscribe((data) => {
            this.store.dispatch(setControlPointLoading({ payload: false }));
            if (data !== null) {
                if (this.windVector) {
                    this.windVector.setData(data);
                } else {
                    this.createWindLayer(data);
                    if (this.currentMapStyleType === MapStyleType.cityair) {
                        this.map?.addLayer(this.windLayer, 'building');
                    } else {
                        this.map?.addLayer(this.windLayer);
                    }
                }
            } else {
                this.store.dispatch(
                    addAlert({
                        id: new Date().valueOf(),
                        messageKey: 'windLoadError',
                        positionX: 'left',
                        positionY: 'bottom',
                        iconClass: 'error',
                        duration: 10000,
                        showCloseIcon: true,
                        size: 'lg',
                    })
                );
            }
        });
    }

    private createWindLayer(windData) {
        const _self = this;
        this.windLayer = {
            id: 'wind',
            type: 'custom',
            onAdd: function (map, gl) {
                _self.windVector = WindVector(gl, map, false);
                _self.windVector.setData(windData);
            },
            render: function (gl, matrix) {
                _self.windVector.draw();
            },
        };
    }

    private setMapStyle() {
        let style: Style | string = EMPTY_MAP_STYLE;
        if (this.currentMapStyleType === MapStyleType.satellite) {
            style = SATELLITE_STYLE;
        } else if (this.currentMapStyleType === MapStyleType.osm) {
            style = OSM_STYLE;
        } else if (this.currentMapStyleType === MapStyleType.outdoor) {
            style = OUTDOOR_STYLE;
        } else if (this.currentMapStyleType === MapStyleType.cityair && this.mapSettings.style) {
            style = this.mapSettings.style;
        }
        if (style) {
            setTimeout(() => {
                this.map.setStyle(style);
                this.mapboxFacadeService.skipCustomStyles();
            }, 200);
        }
    }

    private updateWindLayer() {
        const mapLayer = this.map?.getLayer('wind');
        if (typeof mapLayer !== 'undefined') {
            this.map?.removeLayer('wind');
        }
        if (this.currentMapStyleType === MapStyleType.cityair) {
            this.map?.addLayer(this.windLayer, 'building');
        } else {
            this.map?.addLayer(this.windLayer);
        }
    }

    private toggleWindLayer(data) {
        if (this.map) {
            const mapLayer = this.map?.getLayer('wind');
            if (typeof mapLayer !== undefined && !data) {
                this.map?.removeLayer('wind');
            } else if (data && this.windLayer) {
                if (this.currentMapStyleType === MapStyleType.cityair) {
                    this.map?.addLayer(this.windLayer, 'building');
                } else {
                    this.map?.addLayer(this.windLayer);
                }
            }
        }
        this._cdr.markForCheck();
    }
}
